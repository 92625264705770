<template>
  <div class="main">
    <lj-nav></lj-nav>
    <div class="main-title">学生个人报告中心</div>
    <div class="query-info-wrapper">
      <div class="questionnaire">
        <div class="evaluation-item">
          <div class="item-title">1. 职业倾向报告</div>
          <div class="item-content">
            <p>
              在职业兴趣测试的帮助下，个体可以清晰地了解自己的职业兴趣类型和在职业选择中的主观倾向，从而在纷繁的职业机会中找寻到最适合自己的职业，避免职业选择中的盲目行为。
            </p>
          </div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-primary"
              v-if="!questionaireHollandDone"
              @click="jumpToPage(1)"
              style="margin-right: 1rem"
            >
              开始测评</button
            ><button
              class="lj-btn lj-btn-secondary"
              v-if="questionaireHollandDone"
              @click="jumpToPage(1)"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">2. 人格倾向报告</div>
          <div class="item-content">
            <p>
              每个人都会沿着自己所属的类型发展出个人行为、技巧和态度，而每一种也都存在着自己的潜能和潜在的盲点。本次测试主要探讨各种性格类型与相关职业的匹配程度。
            </p>
          </div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-primary"
              v-if="!questionaireMBTIDone"
              @click="jumpToPage(2)"
              style="margin-right: 1rem"
              :disabled="!questionaireHollandDone"
            >
              开始测评</button
            ><button
              class="lj-btn lj-btn-secondary"
              v-if="questionaireMBTIDone"
              @click="jumpToPage(2)"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">3. 创新素质指数报告</div>
          <div class="item-content">
            <p>
              我们的创新素质指数(Innovative quality
              index)从您的内在特质中提炼出包括创新意识、创新思维、创新知识和创新人格相关的参数，并通过我们的智能算法计算，最终生成您的创新素质指数。
            </p>
          </div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-primary"
              v-if="!questionaireDone"
              @click="jumpToPage(3)"
              style="margin-right: 1rem"
              :disabled="!questionaireHollandDone || !questionaireMBTIDone"
            >
              开始测评</button
            ><button
              class="lj-btn lj-btn-secondary"
              v-if="questionaireDone"
              @click="jumpToPage(3)"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">4. 学科优势报告</div>
          <div class="item-content">
            <p>
              学科优势测评报告是在测评数据的支持下，从学生兴趣出发，针对学生测评结果对学生学科优势做出的分析。通过此项测试，可帮助了解各科情况同时根据各项能力值给到匹配的选科与专业推荐
            </p>
          </div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-primary"
              v-if="!questionaireDone"
              @click="jumpToPage(4)"
              style="margin-right: 1rem"
              :disabled="!questionaireMBTIDone"
            >
              开始测评</button
            ><button
              class="lj-btn lj-btn-secondary"
              v-if="questionaireDone"
              @click="jumpToPage(4)"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">5.学科能力分析报告</div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-secondary"
              @click="jumpToPage('subject')"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">6.考试成绩和排名</div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-secondary"
              @click="jumpToPage('scoreandrank')"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">7. 高校大类专业匹配全景报告</div>
          <!-- <div class="item-content">
            <p>
              我们的研发者根据您的内在特质，利用我们独有的专利智能专业匹配算法（专利号：201810642977.1）为您提供专业匹配报告。该报告建立在我们对上万条数据的智能分析与测评基础之上，它是您可以信赖的专业匹配报告。
            </p>
            <p>
              包括高校大类专业匹配全景报告，最适合的高校大类专业详细报告,
              最适合专业的小类排序报告。
            </p>
          </div> -->
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-primary"
              v-if="!questionaireDone"
              @click="jumpToPage(5)"
              style="margin-right: 1rem"
              :disabled="!questionaireHollandDone || !questionaireMBTIDone"
            >
              开始测评</button
            ><button
              class="lj-btn lj-btn-secondary"
              v-if="questionaireDone"
              @click="jumpToPage(5)"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        
        <div class="evaluation-item">
          <div class="item-title">8.最匹配专业详情报告(20个)</div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-secondary"
              @click="jumpToPage(6)"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">9.小类专业排序报告</div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-secondary"
              @click="jumpToPage(7)"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">10.二十个最匹配专业选科要求</div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-secondary"
              @click="jumpToPage('matchrequirements')"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">11.选科推荐报告</div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-secondary"
              @click="jumpToPage('xuankerecommand')"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="item-title">12.选科规划报告</div>
          <div class="button-wrapper">
            <button
              class="lj-btn lj-btn-secondary"
              @click="jumpToPage('xuanke')"
              style="margin-right: 1rem"
            >
              查看报告
            </button>
          </div>
        </div>

        <div class="evaluation-item" v-if="questionaireDone">
          <div class="item-content">
            <p>
              如果您对测评结果还有疑问，或需要进一步解释，可以点击下方按钮，请学业规划老师的面对面指导，解读您的专业定位报告。
            </p>
            <p>
              您将得到资深的学业规划专家对报告的深度解读，并为您提供量身定做的生涯规划方案。
            </p>
          </div>
        </div>
        <div
          class="evaluation-item consult-btn"
          style="background: #da7152"
          @click="jumpToPage('consult')"
        >
          报告解读与生涯服务咨询
          <i class="el-icon-question"></i>
        </div>
        <div>
          <button
            class="lj-btn lj-btn-secondary"
            v-if="questionaireDone"
            @click="jumpToPage('home')"
            style="margin-right: 1rem"
          >
            返回首页
          </button>
        </div>
      </div>
    </div>
    <lj-footer></lj-footer>
  </div>
</template>

<script>
import { infoquery, agreeliability } from './../../../api/userinfo'
import { queryMajorDone } from './../../../api/major'
import { querySubjectDone } from './../../../api/subject'

import './../../../Public/utils/chartjs/Chart'
import './../../../Public/utils/highcharts/highcharts'
import './../../../Public/utils/new-index/js/jarallax'
import './../../../Public/utils/new-index/js/jquery.totemticker'
import './../../../Public/utils/new-index/js/move-top'
import './../../../Public/utils/new-index/js/easing'
import LjFooter from './../../../components/footer.vue'
import LjNav from './../../../components/nav.vue'
import $ from 'jquery'
$(document).ready(function ($) {
  $('.scroll').click(function (event) {
    event.preventDefault()
    $('html,body').animate(
      {
        scrollTop: $(this.hash).offset().top
      },
      1000
    )
  })
})

$(document).ready(function () {
  $().UItoTop({
    easingType: 'easeOutQuart'
  })
})
export default {
  data: function () {
    return {
      agreementAgreed: false,

      questionaireHollandDone: true,
      questionaireMBTIDone: true,
      questionaireDone: true,

      assresults: {
        chinese: 20,
        math: 20,
        english: 40,
        physics: 20,
        chemistry: 30,
        biology: 30,
        politics: 29,
        history: 19,
        geography: 78
      }
    }
  },
  computed: {
    title: function () {
      return this.agreementAgreed ? '测评导航' : '产品说明'
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  components: {
    LjFooter,
    LjNav
  },
  methods: {
    /**
     * 产品说明，同意或者不同意
     */
    onDisagreeBtnClick: function () {
      this.$router.back()
    },
    onAgreeBtnClick: function () {
      agreeliability({
        agreemajormatching: 1
      }).then(res => {
        if (res.rescode == 200) {
          this.agreementAgreed = !0
        }
      })
    },
    /**
     * 将做题的顺序和部分进行对换
     */
    getPartDone: function (cur_q) {
      this.questionaireHollandDone = cur_q > 26 || cur_q === -1
      this.questionaireMBTIDone = cur_q > 53 || cur_q === -1
      this.questionaireDone = cur_q > 133 || cur_q === -1
    },
    /**
     * 查询学科能力测评是否一个都没做
     */
    judgeDoneQues: function () {
      let ans = false
      Object.entries(this.assresults).forEach(entry => {
        const [key, value] = entry
        if (value !== null) {
          ans = true
        }
      })
      return ans
    },
    jumpToPage: function (e) {
      if (e == 'home') {
        this.$router.replace({
          path: '/'
        })
        return
      }
      switch (e) {
        case 1:
          this.questionaireHollandDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage1'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 2:
          this.questionaireMBTIDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage2'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 3:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage3'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 4:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage4'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 5:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage5'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 6:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage6'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 7:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage7'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 'consult':
          this.$router.push({
            path: 'ConsultUs'
          })
          break
        case 'xuanke':
          this.$router.push({
            path: 'monixuanke'
          })
          break
        case 'subject':
          querySubjectDone({}).then(res => {
            console.log('querySubjectDone', res)
            if (res.rescode == 200) {
              this.assresults = res.resultStatus
              if (!this.judgeDoneQues()) {
                this.$router.push({
                  path: 'HomeTestIndex'
                })
              } else {
                this.$router.push({
                  path: 'HomeTestResult'
                })
              }
            }
          })
          break
        case 'matchrequirements':
          this.$router.push({
            path: 'matchrequirements'
          })
          break
        case 'scoreandrank':
          this.$router.push({
            path: 'scoreandrank'
          })
          break
        case 'xuankerecommand':
          this.$router.push({
            path: 'xuankerecommand'
          })
          break
      }
    },
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    }
  },
  mounted: function () {
    console.log('移动设备')
    if (this.isMobile === null) {
      this.$router.replace({
        path: 'reportCenter'
      })
      return
    }
    infoquery({}).then(res => {
      if (res.rescode == 200) {
        this.agreementAgreed = res.agreemajormatching
        queryMajorDone({}).then(res => {
          if (res.rescode == 200) {
            console.log(res)
            this.getPartDone(res.cur_q)
          }
        })
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/components/base.min.css');
@import url('./../../../Public/resources/css/components/icon.min.css');
@import url('./../../../Public/resources/css/components/modal.min.css');
@import url('./../../../Public/resources/css/components/nav.min.css');
@import url('./../../../Public/resources/css/components/footer.min.css');
@import url('./../../../Public/utils/element-ui/index.css');
@import url('./../../../Public/resources/css/components/button.min.css');
@import url('./../../../Public/resources/css/components/table.min.css');
@import url('./../../../Public/resources/css/components/utility.min.css');
@import url('./../../../Public/resources/css/templates/home/test/index.min.css');
@import url('./../../../Public/resources/css/components/alert.min.css');
@import url('./../../../Public/resources/css/components/form.min.css');
@import url('./../../../Public/resources/css/components/close.min.css');

* {
  text-align: left;
}

.evaluation-item {
  margin-bottom: 1rem;
}

.consult-btn {
  text-align: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  padding: 1rem;
  margin: 1rem;
}
</style>
