/**
 * 专业匹配测评
 */

import { Service } from '@/utils/Service'

// 查询做题进度
export function queryMajorDone (data) {
  return Service({
    url: '/majormatchingeval/queryprogress/',
    data: data
  })
}

// 根据需要查询做到哪个题目并保存进度
export function queryQuesAndSave (data) {
  return Service({
    url: '/majormatchingeval/saveanswergetqus/',
    data: data
  })
}

// 查询霍兰德六个维度
export function queryHolland (data) {
  return Service({
    url: '/majormatchingeval/getholland/',
    data: data
  })
}

// 查询MBTI
export function queryMBIT (data) {
  return Service({
    url: '/majormatchingeval/getmbti/',
    data: data
  })
}

// 查询创新指数
export function queryCxcy (data) {
  return Service({
    url: '/majormatchingeval/getinovationindex/',
    data: data
  })
}

// 查询专业匹配度
export function queryMatchingVal (data) {
  return Service({
    url: '/majormatchingeval/getmatchingval/',
    data: data
  })
}

// 
export function queryMatchingMajors (data) {
  return Service({
    url: '/majormatchingeval/getmatchingmajors/',
    data: data
  })
}

// 
export function queryMatchingMajorsDetails (data) {
  return Service({
    // url: '/majormatchingeval/getmatchingmajordetails/',
    url:'/majormatchingeval/getnewmatchingmajordetails/',
    data: data
  })
}

// 
export function querySubject (data) {
  return Service({
    url: '/majormatchingeval/getsubject/',
    data: data
  })
}
